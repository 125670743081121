<script lang="ts" setup>
import { Role } from "~/types/role";
import Categories from "~/components/pages/catalog/CategoriesCarousel.vue";

const { parentSpecifications } = useDictionaryStore()

const specificationCarouselItems = computed(() => {    
    return parentSpecifications.filter(item => !item.parent && item.is_popular)
})

</script>

<template>
    <div class="min-h-screen">
        <!-- <LoaderLottieAnimation /> --> <!-- Анимация загрузки (Lottie) -->
        <LayoutsHeader /> <!-- Компонент шапки сайта -->

        <div class="container min-h-[950px]">
            <div class="my-10">
                <Categories :items="specificationCarouselItems" :role="Role.CUSTOMER" has_arrows />
            </div>
            <NuxtPage />
        </div>

        <LayoutsFooter />
    </div>
</template>